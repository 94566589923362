import { ILogService, IPromise } from "angular";
import {
    BusUserCredentials, TopicMessageContainer, TopicMessage, StompMessageContext, ReferenceTypes, WebsocketEvents
} from "atfcore-commonclasses";

module BusService {
    let app = angular.module("app");
    /**
     * Servizio per l'utilizzo del bus
     */
    app.factory("BusService",
        ["GlobalApplicationData", "$log", "$q", "$timeout", "BusServiceUtils", "KurentoUtils", "$window", "$interval", "WebinarRoomService",
            (GlobalApplicationData: any, $log: ILogService, $q: angular.IQService, $timeout: ng.ITimeoutService, BusServiceUtils: any, KurentoUtils: any, $window: ng.IWindowService,
                $interval: ng.IIntervalService, WebinarRoomService: any) => {

                var _client: any = null;
                var _busSubscriptions: any[] = [];
                var _busUserCredential: BusUserCredentials = null;
                var _webRtcPeer: any = null;
                var _itemId: string;
                var _lastPing: any;
                var intervals: any;


                // webinar
                var _startWebinarPresenterSuccessCallback: Function;
                var _startWebinarViewerSuccessCallback: Function;
                var _startedPreseterCallback: Function;
                var _stoppedWebinarCallback: Function;
                var _lostConnetcionToStompCallback: Function; 

                var BusWrapper = {

                    connectToBus: _connectToBus,

                    sendSignalingMessage: _sendSignalingMessage,

                    subscribeToQueue: _subscribeToQueue,

                    startWebinarPresenter: _startWebinarPresenter,

                    startWebinarRecording: _startWebinarRecording,

                    continueWebinarRecording: _continueWebinarRecording,

                    pauseWebinarRecording: _pauseWebinarRecording,

                    stopWebinarRecording: _stopWebinarRecording,

                    startWebinarViewer: _startWebinarViewer,

                    createTemporaryUserQueue: _createTemporaryUserQueue,

                    // todo:rivedere esposizione di questo metodo
                    messageUserRtcSignalingQueue: _messageUserRtcSignalingQueue,
                    messageUserBroadCastingVideoQueue: _messageUserBroadCastingVideoQueue,
                    setStartedPresenterCallBack: _setStartedPresenterCallBack,
                    setLostStompConnetcionCallback: _setLostStompConnetcionCallback,
                    stopWebinar: _stopWebinar,

                    dispose: _dispose
                };

                function _connectToBus(): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer(); 

                    if (_client && _client.connected) {
                        deferred.resolve(_client);
                    } else {

                    // todo: evitare questa chiamata se il client esiste ed/o è connesso.
                    // todo2: evitare di richiamare se si conoscono già le credenziali   
                        BusServiceUtils.busUserCredentials.query().$promise
                            .then((data: any) => {

                                if (!data || (data && data.error)) {
                                    deferred.reject(data.error || "No response from busUserCredentials");
                                } else {
                                    _busUserCredential = data.response;

                                    if (!_client) {
                                        _client = BusServiceUtils.stomp.client(_busUserCredential.endpoint);
                                    }

                                    if (!_client.connected) {


                                        // connetto al bus
                                        _client.connect(
                                            _busUserCredential.user,
                                            _busUserCredential.password,
                                            onConnect,
                                            onConnectError,
                                            _busUserCredential.vHost
                                        );

                                    } else {
                                        $log.warn("bus already connected!");
                                        deferred.resolve(_client);
                                    }
 
                                }

                                  

                            function onConnect(args: any) {
                                $log.info("bus connection success:", args);
                                deferred.resolve(_client);
                            }

                            function onConnectError(args: any) {
                                $log.error("bus connection error:", args);
                                if(_lostConnetcionToStompCallback) _lostConnetcionToStompCallback();
                                deferred.reject("Bus connection error!");
                            }
                        })
                        .catch((error: any) => {
                            $log.error(error);
                            deferred.reject(error);
                        });                        
                    }

                    return deferred.promise;
                }

                //avvisiamo chi utilizza il bus di una eventuale perdita di connesione con il client stomp
                //in questo medo possiamo riascoltare le code
                function _setLostStompConnetcionCallback(lostConnetcionToStompCallback: Function) {
                    _lostConnetcionToStompCallback = lostConnetcionToStompCallback;
                }

                function _subscribeToQueue(queueName: string, messageHandler: Function, durable?: Boolean, autodelete?: Boolean): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    if (angular.isUndefined(queueName)) {
                        deferred.reject("subscribeToQueue: queueName cannot be undefined!");
                    }
                    else {
                        durable = angular.isDefined(durable) ? durable : false;
                        autodelete = angular.isDefined(autodelete) ? autodelete : true;

                        _connectToBus()
                            .then(() => {
                                try {
                                    let subscription: any = _client.subscribe(
                                        "/queue/" + queueName,
                                        messageHandler,
                                        // todo: recuperare da configurazione
                                        {
                                            "auto-delete": autodelete,
                                            "durable": durable,
                                            "exclusive": false,
                                            "x-max-length": 4294967000,
                                            "x-message-ttl": 4294967000
                                        });

                                    $log.info(`subscription for ${queueName}: ${subscription}`);

                                    _busSubscriptions.push(subscription);

                                    deferred.resolve();

                                } catch (error) {
                                    deferred.reject(error);
                                }
                            })
                            .catch((error: any) => {
                                deferred.reject(error);
                            });
                    }

                    return deferred.promise;
                }

                function _sendSignalingMessage(message: TopicMessageContainer): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    let messageString: string = JSON.stringify(message);

                    let headers: any = {
                        "content-type": "application/json;charset=utf-8",
                        "content-length": messageString.length,
                        "auto-delete": false,
                        "durable": true,
                        "exclusive": false,
                        "x-max-length": 4294967000,
                        "x-message-ttl": 4294967000
                    };

                    // invio il messaggio nella coda
                    _connectToBus()
                        .then(() => {
                            try {
                                $log.debug(`sending message to queue "${_busUserCredential.queuesConfigurations.webRtcSignaling.name}"`);
                                _client.send(_busUserCredential.queuesConfigurations.webRtcSignaling.name, headers, messageString);
                            } catch (error) {
                                $log.error(error);
                                deferred.reject(error);
                            }

                            $log.info("message sent", message);
                            deferred.resolve();
                        })
                        .catch((error: any) => {
                            $log.error("sendMessage", error);
                            deferred.reject(error);
                        });

                    return deferred.promise;
                }

                function _startWebinarPresenter(itemId: string, videoTag: any, startWebinarPresenterSuccessCallback: Function, stopWebinarCallback: Function): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("1) starting webinar presenter...");

                    _createSdpOfferPresenter(videoTag)
                        .then((sdpOfferResponse: string) => {

                            $log.debug("4) sending sdpOfferResponse", sdpOfferResponse);

                            // preparo il messaggio stomp
                            let stompContext: StompMessageContext = {
                                userId: GlobalApplicationData.jwtPayload.user.userId,
                                referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                                referenceId: _itemId,
                                token: GlobalApplicationData.identityToken,
                                uniqueDevice: GlobalApplicationData.deviceId,
                                action: "start-presenter" // todo: WebsocketEvents.SIGNALING_START_PRESENTER
                            };

                            let topicMessage: TopicMessage = {
                                referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                                data: sdpOfferResponse,
                                additionalData: null
                            };

                            let messageContainer: TopicMessageContainer = {
                                body: topicMessage,
                                customProperties: stompContext
                            };

                            _startWebinarPresenterSuccessCallback = startWebinarPresenterSuccessCallback;
                            _stoppedWebinarCallback = stopWebinarCallback; 
                            return _sendSignalingMessage(messageContainer);
                        })
                        .then(() => {
                            $log.info("5) sdp offer message sent");
                            deferred.resolve();
                        })
                        .catch((error: any) => {
                            $log.error(error);
                            deferred.reject(error);
                        });

                    return deferred.promise;
                }
               

                function _startWebinarRecording(itemId: string) : IPromise<any> {
                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("start webinar recording...");

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "start-recording" // todo: WebsocketEvents.SIGNALING_START_RECORDING
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: "",
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage( messageContainer);                        
                }

                function _continueWebinarRecording(itemId: string) : IPromise<any> {
                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("continue webinar recording...");

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "continue-recording" // todo: WebsocketEvents.SIGNALING_START_RECORDING
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: "",
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage(messageContainer);                        
                }

                function _pauseWebinarRecording(itemId: string) : IPromise<any> {
                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("pause webinar recording...");

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "pause-recording" // todo: WebsocketEvents.SIGNALING_PAUSE_RECORDING
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: "",
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage(messageContainer);                        
                }

                function _stopWebinarRecording(itemId: string) : IPromise<any> {
                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info(" stop webinar recording...");

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "stop-recording" // todo: WebsocketEvents.SIGNALING_STOP_RECORDING
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: "",
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage(messageContainer);                        
                }

                function _startWebinarViewer(itemId: string, videoTag: any, startWebinarViewerSuccessCallback: Function, stopWebinarCallback: Function): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("1) starting webinar viewer...");

                    _createSdpOfferViewer(videoTag)
                        .then((sdpOfferResponse: string) => {

                            $log.debug("4) sending sdpOfferResponse", sdpOfferResponse);

                            // preparo il messaggio stomp
                            let stompContext: StompMessageContext = {
                                userId: GlobalApplicationData.jwtPayload.user.userId,
                                referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                                referenceId: _itemId,
                                token: GlobalApplicationData.identityToken,
                                uniqueDevice: GlobalApplicationData.deviceId,
                                action: "connect-view" // todo: WebsocketEvents.SIGNALING_CONNECT_VIEW
                            };

                            let topicMessage: TopicMessage = {
                                referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                                data: sdpOfferResponse,
                                additionalData: null
                            };

                            let messageContainer: TopicMessageContainer = {
                                body: topicMessage,
                                customProperties: stompContext
                            };
                            _startWebinarViewerSuccessCallback = startWebinarViewerSuccessCallback;
                            _stoppedWebinarCallback = stopWebinarCallback; 
                            return _sendSignalingMessage(messageContainer);
                        })
                        .then(() => {
                            $log.info("5) sdp offer message sent");
                            deferred.resolve();
                        })
                        .catch((error: any) => {
                            $log.error(error);
                            deferred.reject(error);
                        });

                    return deferred.promise;
                }

                // todo: Spostare su servizio dedicato?
                function _createSdpOfferPresenter(videoTag: any): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    $log.debug(`2) createSdpOfferPresenter with videoTag: ${videoTag}`);

                    if (!_webRtcPeer) {

                        var options: any = {
                            //sendSource: "screen",
                            localVideo: videoTag,
                            onicecandidate: _onIceCandidate

                            /*,
                            // TODO: Se sarà necessario recuperare dalla configurazione!!
                            configuration: {
                                iceTransportPolicy: 'all',
                                iceServers: [
                                    {
                                        "url": "stun:13.69.48.90"
                                    },
                                    {
                                        "url": "turn:13.69.48.90:3478",
                                        "username": "atfkurento",
                                        "credential": "kurentoatf"
                                    }]
                            }
                            */
                        };

                        if (_busUserCredential.natTraversalConfiguration) {
                            options.configuration = _busUserCredential.natTraversalConfiguration;
                        }

                        _webRtcPeer = KurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function (error: any) {
                            if (error) {
                                $log.info("KurentoUtils.WebRtcPeer Lost connection");
                                $log.error(error);
                                deferred.reject(error);
                            }

                            this.generateOffer(onOfferGenerated);
                        });

                        $log.debug("_webRtcPeer", _webRtcPeer);
                    }

                    function onOfferGenerated(error: any, offerSdp: any) {
                        $log.debug("3) onOfferGenerated: ", offerSdp);

                        if (error) {
                            $log.error(error);
                            deferred.reject(error);
                        } else {
                            deferred.resolve(offerSdp);
                        }
                    }


                    return deferred.promise;
                }

                // todo: Spostare su servizio dedicato?
                function _createSdpOfferViewer(videoTag: any): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    $log.debug(`2) createSdpOfferViewer with videoTag: ${videoTag}`);

                    if (!_webRtcPeer) {

                        var options: any = {
                            remoteVideo: videoTag,
                            onicecandidate: _onIceCandidate
                            /*,
                            // TODO: Se sarà necessario recuperare dalla configurazione!!
                            configuration: {
                                iceTransportPolicy: 'all',
                                iceServers: [
                                    {
                                        "url": "stun:13.69.48.90"
                                    },
                                    {
                                        "url": "turn:13.69.48.90:3478",
                                        "username": "atfkurento",
                                        "credential": "kurentoatf"
                                    }]
                            }
                            */
                        };

                        _webRtcPeer = KurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error: any) {
                            if (error) {
                                $log.error(error);
                                deferred.reject(error);
                            }

                            this.generateOffer(onOfferGenerated);
                        });

                        $log.debug("_webRtcPeer", _webRtcPeer);
                    }

                    function onOfferGenerated(error: any, offerSdp: any) {
                        $log.debug("3) onOfferGenerated: ", offerSdp);

                        if (error) {
                            $log.error(error);
                            deferred.reject(error);
                        } else {
                            deferred.resolve(offerSdp);
                        }
                    }

                    return deferred.promise;
                }

                // todo: Spostare su servizio dedicato?
                function _onIceCandidate(candidateResponse: any): void {
                    $log.debug("Candidate", candidateResponse);

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "ice-candidate" // todo: WebsocketEvents.SIGNALING_ICE_CANDIDATE
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: {
                            id: 'onIceCandidate',
                            candidate: candidateResponse
                        },
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };


                    // scrivo nella coda il messaggio per triggerare sul mediator l'onIceCandidate
                    BusWrapper.sendSignalingMessage(messageContainer);

                }

                function _stopWebinar(itemId: string): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("1) stopping webinar ...");
                    _destroyIntervals();
                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "stop-webinar" // todo: WebsocketEvents.SIGNALING_STOP_WEBINAR
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: null,
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage(messageContainer)
                        .then(() => { 
                            $log.info("2) stop message sent");
                            deferred.resolve();
                        })
                        .catch((error: any) => {
                            $log.error(error);
                            deferred.reject(error);
                        });
                }


                function _pong(itemId: string): IPromise<any> {

                    let deferred: ng.IDeferred<any> = $q.defer();

                    _itemId = itemId;

                    $log.info("Send pong message ...");

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "__pong__" // todo: WebsocketEvents.SIGNALING_STOP_WEBINAR
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: null,
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };

                    return _sendSignalingMessage(messageContainer)
                        .then(() => {
                            $log.info("pong message sent");
                            deferred.resolve();
                        })
                        .catch((error: any) => {
                            $log.error(error);
                            deferred.reject(error);
                        });
                }

                function _notificationPresenterStarted(itemId: string): IPromise<any> {

                    _itemId = itemId;

                    // preparo il messaggio stomp
                    let stompContext: StompMessageContext = {
                        userId: GlobalApplicationData.jwtPayload.user.userId,
                        referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                        referenceId: _itemId,
                        token: GlobalApplicationData.identityToken,
                        uniqueDevice: GlobalApplicationData.deviceId,
                        action: "started-presenter" // todo: WebsocketEvents.SIGNALING_STARTED_PRESENTER
                    };

                    let topicMessage: TopicMessage = {
                        referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                        data: null,
                        additionalData: null
                    };

                    let messageContainer: TopicMessageContainer = {
                        body: topicMessage,
                        customProperties: stompContext
                    };
                    return _sendSignalingMessage(messageContainer);

                }

                // todo: spostare la creazione su servizio seprato ed eseguirlo
                // solo una volta all'apertura della webinarRoom
                function _createTemporaryUserQueue(itemId: string): ng.IPromise<any> {

                    $log.debug("1) createTemporaryUserQueue");

                    let deferred = $q.defer();

                    BusServiceUtils.createTemporaryUserQueue.query({ uniqueDeviceId: GlobalApplicationData.deviceId, itemId: itemId }).$promise
                        .then((data: any) => {
                            $log.debug("createTemporaryUserQueuePromise", data);
                            deferred.resolve(data.response);
                        })
                        .catch((error: any) => {
                            deferred.reject(error);
                        });

                    return deferred.promise;
                }

                function _messageUserRtcSignalingQueue(message: any) {
                    try {

                        console.log('signaling incoming:', message);
                        let stompMessage = JSON.parse(message.body);
                        let data = JSON.parse(stompMessage.body.data);
                        let customProperties = stompMessage.customProperties;

                        if(data.error){
                            //Todo: da gestire il tipo di errore
                            if (_stoppedWebinarCallback) _stoppedWebinarCallback();
                          }
                          else
                          {
                               
                          
                        
                        switch (data.id) {
                            case 'presenterResponse':
                                $timeout(
                                    function () {
                                        _webRtcPeer.processAnswer(data.sdpAnswer);
                                        NotifyPresenterLive(customProperties.referenceId);
                                        CheckWebSocketConnection();
                                    }, GlobalApplicationData.delayStartPresenter);
                                //interval keep alive presenter

                                break;
                            case 'viewerResponse':
                                $timeout(function () {
                                    _webRtcPeer.processAnswer(data.sdpAnswer);
                                    CheckWebSocketConnection();
                                    if (_startWebinarViewerSuccessCallback != null) _startWebinarViewerSuccessCallback();
                                },
                                    GlobalApplicationData.delayStartViewer);
                                break;
                            case 'stopCommunication':
                                if (_stoppedWebinarCallback) _stoppedWebinarCallback();
                                break;
                            case 'iceCandidate':
                                _webRtcPeer.addIceCandidate(data.candidate);
                                break;

                            case '__ping__':
                                var t = new Date();
                                t.setSeconds(t.getSeconds() + 40);
                                _lastPing = t.getTime();
                                _pong(customProperties.referenceId);
                                break;
                            case ('error'):
                                  if(!data.action){
                                    if (_stoppedWebinarCallback) _stoppedWebinarCallback();
                                  }
                                  else
                                  {
                                      //Todo: da gestire il tipo di errore
                                  }
                            break;
                            default:
                                console.error('Unrecognized message', message);
                        }
                     }
                    } catch (err) {
                        $log.error(err);
                    }
                }


                function _messageUserBroadCastingVideoQueue(message: any) {
                    try {

                        console.log('signaling incoming:', message);
                        let stompMessage = JSON.parse(message.body);

                        switch (stompMessage.customProperties.action) {
                            case 'started-presenter':
                                if (_startedPreseterCallback) _startedPreseterCallback();
                                break;
                            default:
                                console.error('_messageUserBroadCastingVideoQueue Unrecognized message', stompMessage);
                        }
                    } catch (err) {
                        $log.error(err);
                    }
                }

                function _setStartedPresenterCallBack(startedPreseterCallback: Function) {
                    _startedPreseterCallback = startedPreseterCallback;
                }

                function NotifyPresenterLive(referenceId: string) {
                    SendPresenterLiveNotification().then(() => {
                        _notificationPresenterStarted(referenceId);
                        if (_startWebinarPresenterSuccessCallback != null) _startWebinarPresenterSuccessCallback();
                    });
                    let interval = $interval(SendPresenterLiveNotification, GlobalApplicationData.notificationPresenteIsConnectMillis);
                    if (!intervals) intervals = [];
                    intervals.push(interval);

                    // this.$scope.$on("$destroy", function () {
                    //     this.$interval.cancel(interval); 
                    // });
                }


                function CheckWebSocketConnection() {
                    let interval = $interval(function () {
                        if (_lastPing < new Date().getTime()) {
                            //qualcosa non va
                            if (_stoppedWebinarCallback) _stoppedWebinarCallback();
                            $interval.cancel(interval);
                        }
                    }, 10000);
                    if (!intervals) intervals = [];
                    intervals.push(interval);
                }

                function SendPresenterLiveNotification(): ng.IPromise<any> {
                    let deferred = $q.defer();

                    WebinarRoomService.WebinarPresenterIsConnect.query({
                        itemId: _itemId
                    }).$promise
                        .then(() => {
                            deferred.resolve();
                        }).catch((error: any) => {
                            deferred.reject(error);
                        });

                    return deferred.promise;
                }

                function _destroyIntervals(){
                    if (intervals) {
                        for (let i = 0; i < intervals.length; i++) {
                            if (intervals[i]) $interval.cancel(intervals[i]);
                        }
                    }
                }


                //dispose wertcpeer and stomp
                function _dispose() {
                    if (intervals) {
                        for (let i = 0; i < intervals.length; i++) {
                            if (intervals[i]) $interval.cancel(intervals[i]);
                        }
                    }

                    //dispose webRtc
                    if (_webRtcPeer) {
                        _webRtcPeer.dispose();
                        _webRtcPeer = null;
                    }

                    //dispose Stomp
                    //step 1 unsubscribe queue
                    if(_client && _client.connected){

                        if(_busSubscriptions){
                            for (let i = 0; i < _busSubscriptions.length; i++) {
                                if (_busSubscriptions[i]) _busSubscriptions[i].unsubscribe(); 
                            }
                        }
 
                       //step 2 disconnect client
                       _client.disconnect(function() {
                        _client = null;
                      });  

                    } 
                }


                return BusWrapper;


            }]);
}